import React from "react";
import "./SingleProject.css";
import { Item } from "react-photoswipe-gallery";
const SingleProject = ({ project }) => {

  let width = 1100;
  let height = 800;

  if (project?.vertical === "false" && project?.squire === "true") {
    width = 1000;
    height = 1000;
  }
  if (project?.vertical === "true" && project?.squire === "false") {
    width = 1000;
    height = 1300;
  }
  console.log(project.img)
  return (

    <div className="img-container   mb-3" >
        <Item
        
           key={project.id}
          original={project.img}
          thumbnail={project.img}
          width={width}
          height={height}
          title={`Copyright © ${new Date().getFullYear()} all rights reserved by Reyad Abedin`}
        >
          {({ ref, open }) => (
            <img ref={ref} onClick={open} src={project.img} key={project.id} alt="img" />
          ) }
        </Item>
     </div>
 
  );
};

export default SingleProject;
